import React from 'react'
import {injectReducer} from '../../../../../../store/store'
import {asyncComponent} from 'react-async-component'
import {VIFAA_COUNTRIES} from "../../../../utils/constants";
import {MODULE_NAMES, PAGES} from "../../../../modules/moduleConstants";

const createRoute = (props) => {
  const ProductionComponent = asyncComponent({
    resolve: () => new Promise(resolve => {
      import('./modules/rawNpkProductionByYearAndZone').then(reducer => {
        injectReducer({
          key: `${VIFAA_COUNTRIES.NIGERIA}_${PAGES.PRODUCTION}_${MODULE_NAMES.RAW_PRODUCTION_BY_YEAR_AND_ZONE}`,
          reducer: reducer.default
        }) // inject reducer
        import('./modules/rawNpkProductionByYear').then(reducer => {
          injectReducer({
            key: `${VIFAA_COUNTRIES.NIGERIA}_${PAGES.PRODUCTION}_${MODULE_NAMES.RAW_PRODUCTION_BY_YEAR}`,
            reducer: reducer.default
          }) // inject reducer
          import('./layouts/mainLayout').then(view => {
            resolve(view);
          });
        });
      });
    })
  })
  return (<ProductionComponent></ProductionComponent>)
}

export default createRoute
