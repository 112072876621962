import React from 'react'
import {Route} from 'react-router'
import HomeRoute from './home'
import PriceRoute from './price'
import UseRoute from './use'
import AvailabilityRoute from './availability'
import DirectoryRoute from './directory'
import HelpRoute from './help'
import DatasetsRoute from './datasets'
import AboutRoute from './about'
import LicenseRoute from './license'
import ProductionRoute from './production'
import WpContentPage from "../layouts/wpContentPage";

const createRoute = (props) => {
  const {store, history} = props
  return (
    <div>
      <Route path="/nigeria/home" render={({ match }) => <HomeRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/nigeria/price" render={({ match }) => <PriceRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/nigeria/use" render={({ match }) => <UseRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/nigeria/availability" render={({ match }) => <AvailabilityRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/nigeria/help" render={({ match }) => <HelpRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/nigeria/datasets" render={({ match }) => <DatasetsRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/nigeria/directory/:id" render={({ match }) => <DirectoryRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/nigeria/directory" exact render={({ match }) => <DirectoryRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/nigeria/production" exact render={({ match }) => <ProductionRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/nigeria/about" render={({ match }) => <AboutRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/nigeria/license" render={({ match }) => <LicenseRoute store={store} history={history} params={match.params}/>}/>
      <Route path="/nigeria/wpContentPage/:slug" render={({ match }) => <WpContentPage store={store} params={match.params}/>}/>
  </div>
  )
}

export default createRoute
