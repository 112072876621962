
export const MODULE_ACTIONS = {
  LOAD_DEFAULT_FILTERS_REQUEST: 'LOAD_DEFAULT_FILTERS_REQUEST',
  LOAD_DEFAULT_FILTERS_SUCCESS: 'LOAD_DEFAULT_FILTERS_SUCCESS',
  LOAD_DEFAULT_FILTERS_FAILURE: 'LOAD_DEFAULT_FILTERS_FAILURE',
  LOAD_FILTER_REQUEST: 'LOAD_FILTER_REQUEST',
  LOAD_FILTER_SUCCESS: 'LOAD_FILTER_SUCCESS',
  LOAD_FILTER_FAILURE: 'LOAD_FILTER_FAILURE',
  LOAD_DATA_REQUEST: 'LOAD_DATA_REQUEST',
  LOAD_DATA_SUCCESS: 'LOAD_DATA_SUCCESS',
  LOAD_DATA_FAILURE: 'LOAD_DATA_FAILURE',
  LOAD_SETTINGS_REQUEST: 'LOAD_SETTINGS_REQUEST',
  LOAD_SETTINGS_SUCCESS: 'LOAD_SETTINGS_SUCCESS',
  LOAD_SETTINGS_FAILURE: 'LOAD_SETTINGS_FAILURE',
  CHANGE_FILTER_VALUE: 'CHANGE_FILTER_VALUE',
  CHANGE_TIME_PERIOD: 'CHANGE_TIME_PERIOD',
  CHANGE_VIEW: 'CHANGE_VIEW',
  LOAD_WATCH_DATA_REQUEST: 'LOAD_WATCH_DATA_REQUEST',
  LOAD_WATCH_DATA_SUCCESS: 'LOAD_WATCH_DATA_SUCCESS',
  LOAD_WATCH_DATA_FAILURE: 'LOAD_WATCH_DATA_FAILURE',
  LOAD_WATCH_CATEGORIES_REQUEST: 'LOAD_WATCH_CATEGORIES_REQUEST',
  LOAD_WATCH_CATEGORIES_SUCCESS: 'LOAD_WATCH_CATEGORIES_SUCCESS',
  LOAD_WATCH_CATEGORIES_FAILURE: 'LOAD_WATCH_CATEGORIES_FAILURE'
}

export const MODULE_NAMES = {
  IMPORTS_BY_PRODUCT: 'IMPORTS_BY_PRODUCT',
  IMPORTS_BY_COUNTRY_AND_PRODUCT: 'IMPORTS_BY_COUNTRY_AND_PRODUCT',
  IMPORTS_BY_COUNTRY_AND_YEAR: 'IMPORTS_BY_COUNTRY_AND_YEAR',
  IMPORTS_BY_YEAR: 'IMPORTS_BY_YEAR',
  IMPORTS_BY_PRODUCT_TYPE: 'IMPORTS_BY_PRODUCT_TYPE',
  UREA_EXPORTS: 'UREA_EXPORTS',
  PLANT_DIRECTORY: 'PLANT_DIRECTORY',
  GOVERNMENT_CONTRIBUTION: 'GOVERNMENT_CONTRIBUTION',
  ANNUAL_SUBSIDIZED_IMPORTS: 'ANNUAL_SUBSIDIZED_IMPORTS',
  ANNUAL_SUBSIDY_COVERAGE: 'ANNUAL_SUBSIDY_COVERAGE',
  EVOLUTION_OF_SUBSIDY_POLICIES: 'EVOLUTION_OF_SUBSIDY_POLICIES',
  FOB_PRICES: 'FOB_PRICES',
  INTERNATIONAL_VS_RETAIL_PRICES: 'INTERNATIONAL_VS_RETAIL_PRICES',
  PRICE_COMPARISON: 'PRICE_COMPARISON',
  THREE_MONTHS_PRICE_COMPARISON: 'THREE_MONTHS_PRICE_COMPARISON',
  PRICE_COMPARISON_OVER_TIME: 'PRICE_COMPARISON_OVER_TIME',
  RETAIL_PRICES: 'RETAIL_PRICES',
  RETAIL_PRICES_OVER_TIME: 'RETAIL_PRICES_OVER_TIME',
  COST_BUILD_UP: 'COST_BUILD_UP',
  APPARENT_CONSUMPTION: 'APPARENT_CONSUMPTION',
  AVERAGE_CONSUMPTION: 'AVERAGE_CONSUMPTION',
  CROPLAND_UNDER_PRODUCTION: 'CROPLAND_UNDER_PRODUCTION',
  USE_BY_CROP: 'USE_BY_CROP',
  NUTRIENT_USE_BY_CROP: 'NUTRIENT_USE_BY_CROP',
  TOP_FERTILIZER_CONSUMING_CROPS: 'TOP_FERTILIZER_CONSUMING_CROPS',
  CONSUMPTION_BY_NUTRIENT: 'CONSUMPTION_BY_NUTRIENT',
  CONSUMPTION_BY_PRODUCT: 'CONSUMPTION_BY_PRODUCT',
  UREA_CONSUMPTION: 'UREA_CONSUMPTION',
  Q_CROPLAND_UNDER_PRODUCTION: 'Q_CROPLAND_UNDER_PRODUCTION',
  TRANSIT_BY_COUNTRY_AND_YEAR: 'TRANSIT_BY_COUNTRY_AND_YEAR',
  TRANSIT_BY_YEAR: 'TRANSIT_BY_YEAR',
  EXPORTS_BY_YEAR: 'EXPORTS_BY_YEAR',
  EXPORTS_BY_COUNTRY: 'EXPORTS_BY_COUNTRY',
  WATCH_INDICATORS_STATUS: 'WATCH_INDICATORS_STATUS',
  RAW_PRODUCTION_BY_YEAR_AND_ZONE: 'RAW_PRODUCTION_BY_YEAR_AND_ZONE',
  RAW_PRODUCTION_BY_YEAR: 'RAW_PRODUCTION_BY_YEAR'
}

export const PAGES = {
  HOME: 'HOME',
  AVAILABILITY: 'AVAILABILITY',
  DIRECTORY: 'DIRECTORY',
  POLICY: 'POLICY',
  PRICE: 'PRICE',
  USE: 'USE',
  EXPORT: 'EXPORT',
  PRODUCTION: 'PRODUCTION'
}
